
import http from "../../../../../common/services/http";
import { defineComponent, ref, onMounted, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import router from "../../router";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const processing = ref(false);
    const customer = ref({});

    const assignments = ref({
      data: []
    });

    function numberOfObjects(qty: {}) {
      const len = Object.keys(qty).length;
      return len + 1;
    }

    function getAssignmentDetails(id: any, keyword: any) {
      if (id) {
        const assignment: any = assignments.value.data;
        const single_assignment = assignment.filter(function(a: any) {
          return a.id == id;
        });
        assignment.filter((a: any) => a.id === id);
        return single_assignment[0][keyword];
      }
    }

    function fetchUser(userId: any) {
      processing.value = true;
      http.get(`/api/users/${userId}`).then(res => {
        customer.value = res.data[0];
        processing.value = false;
      });
    }

    function fetchUserAssignments(id: any) {
      processing.value = true;

      http.get(`/api/assignments?user_id=${id}`).then((res: any) => {
        assignments.value = res.data;
        processing.value = false;
      });
    }

    onMounted(() => {
      if (router.currentRoute.value.params?.id) {
        const u = router.currentRoute.value.params?.id;
        fetchUser(u);
        fetchUserAssignments(u);
      }
    });

    return {
      customer,
      assignments,
      processing,
      numberOfObjects,
      getAssignmentDetails
    };
  }
});
